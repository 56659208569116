import React from "react";
import Container from "../components/container/Container";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

const Home = () => {
  const history = useHistory();
  return (
    <Container>
      <img style={{width: '100%', height: 'auto'}} src="https://www.amz-test.at/wp-content/uploads/2021/01/AmZ-Test-Header-8.jpg" />
      <h1>Willkommen</h1>
      <p style={{ textAlign: "center" }}>
        Hier könen Sie die Bestätigung für Ihren Test herunterladen.
      </p>
      <div>
        <p>1. Halten Sie Ihren Anmeldecode bereit, welchen Sie per SMS und E-Mail erhalten haben.<br></br>
        Anmerkung: Der Code hat 5 oder 7 Stellen und wurde bereits in der Teststation verwendet.</p>
        <p>2. Geben Sie den Code ein und bestätigen Sie mit "Senden".</p>
      </div>
      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: 30 }}
        onClick={() => {
          history.push("/codein");
        }}
      >
        Weiter
      </Button>
    </Container>
  );
};

export default Home;
