import React, { useEffect, useState } from "react";
import Container from "../components/container/Container";
import QrReader from "react-qr-reader";
import "./CodeIn.css";
import { Button, TextField } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import back from "./back.svg";

const URL = "https://befund.amz-test.at/pdfs";

const CodeIn = () => {
  const [perspective, setPerspective] = useState("user");
  const [code, setCode] = useState("");
  const history = useHistory();
  const [error, setError] = useState("");
  const handleScan = (data) => {
    console.log(data);
    if (data) {
      sendCode(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const sendCode = async (code) => {
    try {
      let response;
      if (code.length > 12) {
        response = await axios.post(URL, {
          persistent: code.toLowerCase(),
        });
      } else {
        response = await axios.post(URL, {
          shortid: code.toLowerCase(),
        });
      }
      if (response && response.status === 200) {
        history.push("/pdf/" + code);
      }
    } catch (err) {
      setError(JSON.stringify(err));
      if (err && err.response) {
        if (err.response.status === 404) {
          history.push("notfound");
        }
        if (err.response.status === 409) {
          history.push("conflict");
        }
      }
    }
  };

  return (
    <Container>
      <div
        className="back"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={back} />
      </div>
      <h1>Code eingeben</h1>
      <p> Bitte tippen Sie in das Feld Ihren Anmeldecode ein:</p>
      <div className="qr-code">

        <TextField
          className="textin"
          label="Code"
          variant="filled"
          autoCapitalize="none"
          onChange={(value) => {
            setCode(value.target.value.toLowerCase());
          }}
        />
        <div style={{ width: "100%" }}>{error}</div>
        <Button
          style={{ marginTop: 15 }}
          color="primary"
          variant="contained"
          disabled={code.length < 4}
          onClick={async () => {
            sendCode(code);
          }}
        >
          Befund anfordern
        </Button>
      </div>
    </Container>
  );
};

export default CodeIn;
